<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >
      <!-- Store datails tab -->
      <tab-content title="รายละเอียดร้าน">
        <b-row>
          <b-col cols="5" class="mb-2">
            <h3 class="mb-0">{{ store_name }}</h3>
            <!-- <b-avatar :src="pictrue" size="144px" rounded /> -->
          </b-col>
          <b-col md="5"> </b-col>
          <b-col md="2">
            <div class="demo-inline-spacing">
              <!-- primary -->
              <div>
                <b-card-text class="mb-0"> เปิดใช้งาน </b-card-text>
                <b-form-checkbox
                  v-model="status"
                  checked="true"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </div>
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  @click="del_user()"
                >
                  ลบ
                </b-button>
              </div>
            </div>
          </b-col>
          <!-- <b-col md="2">
            <b-form-file
              ref="file-input"
              size="sm"
              v-model="file"
              class="mb-2"
              type="file"
              accept=".jpeg,.png,.jpg,GIF"
              :hidden="true"
              @change="onFileChange"
            />เลือกรูปภาพร้าน
          </b-col> -->
          <b-col cols="12" class="mb-2"> </b-col>

          <b-col md="6">
            <b-form-group label="ชื่อร้าน" label-for="name">
              <b-form-input v-model="name" id="name" placeholder="ชื่อร้าน" />
              <b-alert v-if="check.name == true" variant="danger" show>
                <span>Please Enter</span>
              </b-alert>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="หมายเลขโทรศัพท์" label-for="phone">
              <b-form-input
                v-model="phone"
                id="phone"
                placeholder="หมายเลขโทรศัพท์"
                type="number"
              />
              <b-alert v-if="check.phone == true" variant="danger" show>
                <span>Please Enter</span>
              </b-alert>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="ละติจูด ลองจิจูด" label-for="address">
              <b-form-input
                readonly
                id="address"
                v-model="address"
                placeholder="ละติจูด ลองจิจูด"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2"> แผนที่</b-col>
          <b-col md="12">
            <gmap-map
              :zoom="14"
              :center="center"
              style="width: 100%; height: 350px"
              @click="handleMapClick"
            >
              <gmap-marker :position="position"> </gmap-marker>
            </gmap-map>
          </b-col>
        </b-row>
      </tab-content>

      <!-- เวลาเปิดทำการ -->
      <!-- <tab-content title="เวลาเปิดทำการ">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">เวลาเปิดทำการ</h5>
          </b-col>
         
          <b-col md="6">
            <b-form-group label="Sunday Start" label-for="sunday_start">
              <b-form-timepicker
                v-model="sunday_start"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Sunday End" label-for="sunday_end">
              <b-form-timepicker
                v-model="sunday_end"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
 
          <b-col md="6">
            <b-form-group label="Monday Start" label-for="monday_start">
              <b-form-timepicker
                v-model="monday_start"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Monday End" label-for="monday_end">
              <b-form-timepicker
                v-model="monday_end"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group label="Tuesday Start" label-for="tuesday_start">
              <b-form-timepicker
                v-model="tuesday_start"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tuesday End" label-for="tuesday_end">
              <b-form-timepicker
                v-model="tuesday_end"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group label="Wednesday Start" label-for="wednesday_start">
              <b-form-timepicker
                v-model="wednesday_start"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Wednesday End" label-for="tuesday_end">
              <b-form-timepicker
                v-model="tuesday_end"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group label="Thursday Start" label-for="thursday_start">
              <b-form-timepicker
                v-model="thursday_start"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Thursday End" label-for="tuesday_end">
              <b-form-timepicker
                v-model="tuesday_end"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group label="Friday Start" label-for="friday_start">
              <b-form-timepicker
                v-model="friday_start"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Friday End" label-for="friday_end">
              <b-form-timepicker
                v-model="friday_end"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group label="Saturay Start" label-for="saturay_start">
              <b-form-timepicker
                v-model="saturay_start"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Saturay End" label-for="saturay_end">
              <b-form-timepicker
                v-model="saturay_end"
                size="lg"
                local="en"
                class="mb-2"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content> -->
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAvatar,
  BFormFile,
  BFormSelect,
  BFormTimepicker,
  BFormCheckbox,
  BAlert,
  BBadge,
  BButton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { gmapApi } from "vue2-google-maps";
import api from "@/api";
export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormCheckbox,
    BAvatar,
    BFormFile,
    BFormSelect,
    BFormTimepicker,
    BFormCheckbox,
    BAlert,
    BBadge,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    google: gmapApi,
  },
  data() {
    return {
      store_name: "",
      id: "",
      pictrue: null,
      name: null,
      phone: null,
      address: null,
      file: null,
      status: true,
      center: { lat: 16.439688394027236, lng: 102.8289296386659 },
      position: { lat: 16.439688394027236, lng: 102.8289296386659 },
      sunday_start: "09:00",
      sunday_end: "18:00",
      monday_start: "09:00",
      monday_end: "18:00",
      tuesday_start: "09:00",
      tuesday_end: "18:00",
      wednesday_start: "09:00",
      wednesday_end: "18:00",
      thursday_start: "09:00",
      thursday_end: "18:00",
      friday_start: "09:00",
      friday_end: "18:00",
      saturay_start: "09:00",
      saturay_end: "18:00",
      check: {
        name: false,
        phone: false,
      },
    };
  },
  mounted() {
    this.store_name = localStorage.getItem("store_name");

    this.loaddata();

    if (!localStorage.getItem("store_id")) {
      this.$swal({
        title: "กรูณาเลือกร้านค้าก่อน!",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.$router.push({ name: "store" });
    }
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
      console.log("file", this.file);
      const image = e.target.files[0];
      this.pictrue = URL.createObjectURL(image); ///show
    },
    formSubmitted() {
      console.log("formSubmitted");
      var access_token = localStorage.getItem("accessToken");
      var userData = localStorage.getItem("userData");
      console.log("access_token :>> ", access_token);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("pictrue", this.pictrue);
      formData.append("id", this.id);
      formData.append("store_id", localStorage.getItem("store_id"));
      formData.append("name", this.name);
      formData.append("phone", this.phone);
      formData.append("lat", this.position.lat);
      formData.append("lng", this.position.lng);
      formData.append("status", this.status);
      formData.append("sunday_start", this.sunday_start);
      formData.append("sunday_end", this.sunday_end);
      formData.append("monday_start", this.monday_start);
      formData.append("monday_end", this.monday_end);
      formData.append("tuesday_start", this.tuesday_start);
      formData.append("tuesday_end", this.tuesday_end);
      formData.append("wednesday_start", this.wednesday_start);
      formData.append("wednesday_end", this.wednesday_end);
      formData.append("thursday_start", this.thursday_start);
      formData.append("thursday_end", this.thursday_end);
      formData.append("friday_start", this.friday_start);
      formData.append("friday_end", this.friday_end);
      formData.append("saturay_start", this.saturay_start);
      formData.append("saturay_end", this.saturay_end);
      if (this.name && this.phone) {
        this.$swal({
          title: "คุณต้องการบันทึกข้อมูล?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "บันทึกข้อมูล!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            api
              .post("/updatebrachbystore", formData, {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log("response", response);
                if (response) {
                  this.$swal({
                    icon: "success",
                    title: "success",
                    showConfirmButton: false,
                    timer: 3000,
                  });
                  localStorage.removeItem("brach_id");
                  this.name = "";
                  this.phone = "";
                  this.position.lat = 16.439688394027236;
                  this.position.lng = 102.8289296386659;
                  this.file = "";
                  this.status = true;
                  this.sunday_start = "09:00";
                  this.sunday_end = "18:00";
                  this.monday_start = "09:00";
                  this.monday_end = "18:00";
                  this.tuesday_start = "09:00";
                  this.tuesday_end = "18:00";
                  this.wednesday_start = "09:00";
                  this.wednesday_end = "18:00";
                  this.thursday_start = "09:00";
                  this.thursday_end = "18:00";
                  this.friday_start = "09:00";
                  this.friday_end = "18:00";
                  this.saturay_start = "09:00";
                  this.saturay_end = "18:00";
                  this.pictrue = "";
                  this.$router.push("/store/detail");
                }
              });
          }
        });
      } else {
        if (!this.name) {
          this.check.name = true;
          console.log("this.check.name :>> ", this.check.name);
        }
        if (!this.phone) {
          this.check.phone = true;
          console.log("this.check.phone :>> ", this.check.phone);
        }

        this.$swal({
          title: "Error!",
          text: " ตรวจสอบข้อมูลใหม่อีกครั้ง!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      const params = {
        store_id: localStorage.getItem("store_id"),
        user_main: userData.user_main,
        name: this.name,
        phone: this.phone,
        lat: this.position.lat,
        lng: this.position.lng,
        file: this.file,
        status: this.status,
        sunday_start: this.sunday_start,
        sunday_end: this.sunday_end,
        monday_start: this.monday_start,
        monday_end: this.monday_end,
        tuesday_start: this.tuesday_start,
        tuesday_end: this.tuesday_end,
        wednesday_start: this.wednesday_start,
        wednesday_end: this.wednesday_end,
        thursday_start: this.thursday_start,
        thursday_end: this.thursday_end,
        friday_start: this.friday_start,
        friday_end: this.friday_end,
        saturay_start: this.saturay_start,
        saturay_end: this.saturay_end,
        SSID: this.SSID,
        Download: this.download,
        Upload: this.upload,
        Session_Timeout: this.Session_Timeout,
        Idle_Timeout: this.Idle_Timeout,
      };
      
    },
    // ---------------mapp ----------
    handleMapClick(e) {
      this.position.lat = e.latLng.lat();
      this.position.lng = e.latLng.lng();
      this.address = String(e.latLng.lat()) + " , " + String(e.latLng.lng());
    },
    loaddata() {
      console.log("this.$route.params.id :>> ", this.$route.params.id);
      const params = {
        id_barch: localStorage.getItem("brach_id"),
      };
      api
        .post("get_brach_by_id_instore", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.data = response.data.data;
          for (let item of response.data.data) {
            this.address = String(item.lat) + " , " + String(item.lng);
            this.store_name = item.name;
            this.id = item.id;
            this.pictrue = item.pictrue;
            this.name = item.name;
            this.phone = item.phone;
            this.status = item.status;
            this.center.lat = item.lat;
            this.center.lng = item.lng;
            this.position.lat = item.lat;
            this.position.lng = item.lng;
            this.sunday_start = item.sunday_start;
            this.sunday_end = item.sunday_end;
            this.monday_start = item.monday_start;
            this.monday_end = item.monday_end;
            this.tuesday_start = item.tuesday_start;
            this.tuesday_end = item.tuesday_end;
            this.wednesday_start = item.wednesday_start;
            this.wednesday_end = item.wednesday_end;
            this.thursday_start = item.thursday_start;
            this.thursday_end = item.thursday_end;
            this.friday_start = item.friday_start;
            this.friday_end = item.friday_end;
            this.saturay_start = item.saturay_start;
            this.saturay_end = item.saturay_end;
            this.download = item.BandwidthDownload;
            this.upload = item.BandwidthUpload;
            this.Session_Timeout = item.SessionTimeout;
            this.Idle_Timeout = item.IdleTimeout;
            this.SSID = item.SSID;
          }
          console.log(" this.selected :>> ", this.selected);
        })
        .catch((error) => {});
    },
    del_user(id) {
      const params = {
        id: this.id,
      };
      console.log("del :params>> ", params);

      this.$swal({
        title: "คุณต้องการสาขานี้?",
        text: "คุณจะไม่สามารถย้อนกลับได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api
            .post("/del_brach", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              console.log("del_brach", response);
              if (response) {
                this.$swal({
                  icon: "success",
                  title: "ลบสำเร็จ!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.$router.push("/store/detail");
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

